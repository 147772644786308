import { APPLYNOW } from '../utils/Endpoints';

// URLs for the Google Forms
const applyFormURL = APPLYNOW; // Replace with the actual form URL
export interface JobModel{
    id: number;
    title: string;
    location: string;
    description: string;
    formURL: string;
    qualifications: string;
    payment: string;
    schedule: string;
}
export const jobList: JobModel[] = [
    {
        id: 1,
        title: 'Full Stack Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Seeking experienced full stack trainer proficient in React and Node.js for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 2,
        title: 'Java Backend Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Looking for a backend trainer with strong Java and Spring Boot skills for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 3,
        title: 'UI/UX Trainer',
        location: 'Remote',
        description: 'Creative designer needed with user-centric design experience for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (Design), B.Sc (Design), relevant diploma or course can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 4,
        title: 'PHP Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Experienced PHP trainer required for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 5,
        title: 'Angular Developer',
        location: 'Remote',
        description: 'Seeking Angular trainer with strong front-end skills for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 6,
        title: 'AWS Cloud Trainer',
        location: 'Remote',
        description: 'AWS cloud expert needed for content creation on cloud.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 7,
        title: 'React Trainer',
        location: 'Remote',
        description: 'Looking for a React trainer skilled in building modern web apps for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 8,
        title: 'Android Trainer',
        location: 'Remote, Delhi, Bangalore',
        description: 'Android trainer with strong Kotlin/Java skills needed for content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), relevant diploma or pursuing courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 9,
        title: 'Testing Engineer',
        location: 'Remote',
        description: 'QA trainer needed for automated and manual testing content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    },
    {
        id: 10,
        title: 'Vue.js Trainer',
        location: 'Remote',
        description: 'Trainer required for Vue.js content creation.',
        formURL: applyFormURL,
        qualifications: 'B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.',
        payment: '100-300 INR per topic, based on experience.',
        schedule: 'Flexible schedule; payment can be weekly, monthly, or daily.'
    }
];