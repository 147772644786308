import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import './Navbar.css';
import courses from '../data/coursesData';
import CourseInquiryForm from './CourseInquiryForm';
import { useTheme } from '../context/ThemeContext';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartItems } = useCart();
  const { isAuthenticated } = useAuth();
  const [selectedCategoryDisplayId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { theme, toggleTheme } = useTheme();

  // Filter courses based on the selected category
  const filteredCourses = selectedCategoryDisplayId
    ? courses.filter((course) => course.categoryDisplayId === selectedCategoryDisplayId)
    : courses;

  const courseData = filteredCourses.map((course) => ({
    id: course.id.toString(), // Convert number to string
    title: course.title,
  }));

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleFormSubmit = (formData: { name: string; mobile: string; email: string; courseId: string; message: string }) => {
    console.log('Form submitted:', formData);
    setShowModal(false); // Close the modal after submission
  };

  const handleClick = () => {
    setShowModal(true)
  };
  return (
    <>
      <div className="in">
    <div className="vertical-button" onClick={handleClick}>
      <i className="fa fa-question" aria-hidden="true"></i>
      <span>Quick Inquiry</span>
    </div>
        {showModal && (
          <CourseInquiryForm
            onClose={() => setShowModal(false)}
            onSubmit={handleFormSubmit}
            courses={courseData}
          />
        )}
      </div>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="https://yt3.ggpht.com/okwHkRkY-r6FzulnoCNk2pFY7W4genVlT9YfAtiFTfhrf5mTjfHGXR0ytqyHPgBeWWMsdZj1sBg=s600-c-k-c0x00ffffff-no-rj-rp-mo"
              alt="Mukund Programming Tutorials Logo"
              className="navbar-logo"
            />
            Mukund Programming Tutorials
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={() => setIsOpen(false)}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/courses" onClick={() => setIsOpen(false)}>
                  Courses
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/services/online-class"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Online Class <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/services/offline-class"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Offline Class <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/project" onClick={() => setIsOpen(false)}>
                      Project <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="resourcesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                </Link>
                <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/resources/roadmap"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Roadmap <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="companyDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </Link>
                <ul className="dropdown-menu" aria-labelledby="companyDropdown">
                  <li>
                    <Link className="dropdown-item" to="/about" onClick={() => setIsOpen(false)}>
                      About <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/contact" onClick={() => setIsOpen(false)}>
                      Contact <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/career" onClick={() => setIsOpen(false)}>
                      Career <i className="rightIcon fa fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cart" onClick={() => setIsOpen(false)}>
                  <i className="cart-icon fa fa-shopping-cart"></i> ({cartItems})
                </Link>
              </li>
              {isAuthenticated ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/profile" onClick={() => setIsOpen(false)}>
                    Profile
                  </Link>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login" onClick={() => setIsOpen(false)}>
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/signup" onClick={() => setIsOpen(false)}>
                      Signup
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div onClick={toggleTheme} className="vertical-links-button">
  {theme === 'light' ? (
    <i className="fa fa-moon-o"></i> // Dark mode icon
  ) : (
    <i className="fa fa-sun-o"></i> // Light mode icon
  )}
</div>

        </div>
      </nav>
    </>
  );
};

export default Navbar;
