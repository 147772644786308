import React, { useState } from 'react';
import Courses from './Courses';
import Category from '../components/Category';
import courses from '../data/coursesData';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import { APPLYNOW } from '../utils/Endpoints';
const Home: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCategoryDisplayId, setSelectedCategoryDisplayId] = useState<string | null>(null);

  // Filter courses based on the selected category
  const filteredCourses = selectedCategoryDisplayId
    ? courses.filter((course) => course.categoryDisplayId === selectedCategoryDisplayId)
    : courses;
  // Handle category selection and navigate to the Courses page
  const handleCategoryClick = (categoryDisplayId: string) => {
    setSelectedCategoryDisplayId(categoryDisplayId === selectedCategoryDisplayId ? null : categoryDisplayId); // Toggle category
    navigate(`/courses?course-category=${categoryDisplayId === selectedCategoryDisplayId ? '' : categoryDisplayId}`); // Navigate with categoryDisplayId
  };


  return (
    <div className="home">
      <HeroSection
        imageUrl="/assets/images/backgrounds/great-product-by-great-team.png"
        link={APPLYNOW}
        heading="Great Product is built by great teams"
        paragraph="Join our community of learners and industry experts. We provide top-tier tech courses designed to help you build in-demand skills, advance your career, and achieve your goals in the ever-evolving tech landscape."
        buttonText="Join Us"
      />
      <Category onCategoryClick={handleCategoryClick} selectedCategoryDisplayId={selectedCategoryDisplayId} />
      <Courses courses={filteredCourses} />
    </div>
  );
};

export default Home;
