import React, { useEffect, useState } from 'react';
import './Category.css'; // Optional styling
import { CourseCategory } from '../data/categoryData';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { getCategory } from '../services/CourseService';

interface CategoryProps {
  onCategoryClick: (categoryDisplayId: string) => void;
  selectedCategoryDisplayId: string | null;
}

const Category: React.FC<CategoryProps> = ({ onCategoryClick, selectedCategoryDisplayId }) => {
  const [categories, setCategories] = useState<CourseCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await getCategory();
        setCategories(categoryData);
      } catch (err) {
        setError('Failed to load categories');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  if (isLoading) return <p>Loading categories...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="categories">
      <h2>Explore Our Course Categories</h2>
      <Slider {...sliderSettings} className="category-slider">
        {categories.map((category) => (
          <div key={category.categoryDisplayId}>
            <div
              className={`category-card ${selectedCategoryDisplayId === category.categoryDisplayId ? 'selected' : ''}`}
              onClick={() => onCategoryClick(category.categoryDisplayId)}
            >
              <i className={`category-icon fa ${category.categoryIcon}`} />
              <h4>{category.name}</h4>
              <p title={category.description}>{category.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Category;
