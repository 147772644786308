import React, { useEffect, useState } from 'react';
import { Course, Topics } from '../data/coursesData';
import './CourseDetails.css'; // Add your styling if needed
import { getCourseById } from '../services/CourseService';
import { Accordion } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface CourseDetailsProps {
  course: Course;
}
const CourseDetails: React.FC<CourseDetailsProps> = ({ course }) => {
  const [loading, setLoading] = useState(true); // State to handle loading
  const { courseDisplayid, courseid } = useParams<{ courseDisplayid: string; courseid: string }>();

  // State to manage the active FAQ item
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function to open/close an FAQ item
  const handleToggle = (index: any) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if the same FAQ is clicked again
    } else {
      setActiveIndex(index);
    }
  };
  const [courseResponse, setCourse] = useState<Course>(course);

  const [activeTab, setActiveTab] = useState<string>('details');
  const [selectedTopic, setSelectedTopic] = useState<Topics | null>(null);

  // Fetch course data when the component mounts or courseid changes
  useEffect(() => {
    const fetchCourseDetails = async () => {
      const courseData = await getCourseById(courseid||'');
      setCourse(courseData);
      setLoading(false)
    };

    fetchCourseDetails();
  }, [courseid]);

  // Automatically select the first topic when the 'Topics' tab is selected
  // Automatically select the first topic when the 'Topics' tab is selected
  useEffect(() => {
    if (activeTab === 'Topics' && courseResponse && courseResponse.Topics && courseResponse.Topics.length > 0) {
      setSelectedTopic(courseResponse.Topics[0]); // Set the first topic as the default selected topic
    }
  }, [activeTab, courseResponse]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'details':
        return (
          <div className='container'>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="details-image-container">
                  <img
                    src={courseResponse?.image}
                    alt={courseResponse?.title}
                    className="img-fluid"
                  />
                </div>

              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 card-body card-body-radius">
             
                <div className="details-content card-body p-3 card-body-radius">
                  <h3>{courseResponse?.title}</h3>

                  <div className="course-details ">
                    {courseResponse?.fee < 1 ? (
                      <div className="course-badge free-course">Free</div>
                    ) : (
                      <div className="course-badge paid-course">
                        👑{courseResponse?.fee} INR</div>

                    )}

                    <div className="rating ">
                      <span>{courseResponse?.rating} ⭐</span>
                    </div>
                    <div className='course-badge course-topics'>Topics: {courseResponse?.Topics.length}</div>
                    <div className="course-badge free-course">{courseResponse?.badgeText}</div>
                  </div>
                  <p className="card-text truncate" title={courseResponse?.description}>{courseResponse?.description}</p>

                  <div className='button-container '>
                    <button onClick={() => setActiveTab('Topics')} className='btn btn-primary viewBtn'>Watch Now</button>
                    <a href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
                      className="btn btn-primary applyBtn">Proceed to Enroll</a>
                  </div>
                </div>
              </div>
             
            </div>
           

          </div>
        );


      case 'Topics':
        return (
          <div className="Topics-tab">
            <h4>Topics ({courseResponse?.Topics.length})</h4>
            <hr></hr>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                <div className='topic-card'>
                  {courseResponse?.Topics.length ? (
                    <ul>
                      {courseResponse?.Topics.map((topic) => (
                        <li className="topicLi" key={topic.id} onClick={() => setSelectedTopic(topic)}
                        >
                          <div className="topic-item">
                            <p><i className='fa fa-video-camera'></i> {topic.title}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Topics available.</p>
                  )}
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'>

                {selectedTopic && selectedTopic.externalLink && selectedTopic.source === 'local' && (
                  <div className="video-container">
                    <video width="560" height="315" controls>
                      <source src={selectedTopic.externalLink} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
                {selectedTopic && selectedTopic.externalLink && selectedTopic.source === 'youtube' && (
                  <div className="video-container">
                    <iframe
                      src={selectedTopic.externalLink}
                      title={selectedTopic.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen></iframe>
                  </div>
                )}

              </div>
            </div>
          </div>
        );
      case 'resources':
        return (
          <div className="resources-tab">

            <h4>Resources ({courseResponse?.resources?.length})</h4>
            <hr></hr>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                {courseResponse?.resources?.length ? (
                  <p></p>
                ) : (
                  <p>No resources available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'prerequisites':
        return (
          <div className="resources-tab">

            <h4>Prerequisites ({courseResponse?.prerequisites?.length})</h4>
            <hr></hr>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                {courseResponse?.prerequisites?.length ? (
                  <p></p>
                ) : (
                  <p>No prerequisit available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'faq':
        return (
          <div className="resources-tab">

            <h4>Resources ({courseResponse?.Faqs?.length})</h4>
            <hr></hr>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                {courseResponse?.Faqs?.length ? (
                  <p></p>
                ) : (
                  <p>No faq available.</p>
                )}
              </div>
            </div>
          </div>
        );
      case 'test':
        return (
          <div className="resources-tab">

            <h4>Test Questions ({courseResponse?.tests?.length})</h4>
            <hr></hr>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-6'>
                {courseResponse?.tests?.length ? (
                  <p></p>
                ) : (
                  <p>No test available.</p>
                )}
              </div>
            </div>
          </div>
        );

      case 'enroll':
        return (
          <div>
            <h4>Enroll in Online Classes</h4>
            <a href={`https://wa.me/8677040031?text=Course: ${courseResponse?.courseDisplayId}.I want to enroll`} target="_blank" rel="noopener noreferrer"
              className="btn btn-primary">Proceed to Enroll</a>
          </div>
        );

      default:
        return null;
    }
  };
  if(loading)
  {
return (
  <div className="course-details-container">
<p className='text text-center'>Loading details...</p>
</div>
);
  }
  else
  {
  return (
    <div className="course-details-container">
      <h2 className='text-center'>Course Details</h2>
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'details' ? 'active' : ''}`}
          onClick={() => setActiveTab('details')}
        >
          Details
        </button>
        <button
          className={`tab ${activeTab === 'Topics' ? 'active' : ''}`}
          onClick={() => setActiveTab('Topics')}
        >
          Topics ({courseResponse?.Topics.length})
        </button>
        <button
          className={`tab ${activeTab === 'resources' ? 'active' : ''}`}
          onClick={() => setActiveTab('resources')}
        >
          Resources
        </button>
        <button
          className={`tab ${activeTab === 'prerequisites' ? 'active' : ''}`}
          onClick={() => setActiveTab('prerequisites')}
        >
          Prerequisites
        </button>
        <button
          className={`tab ${activeTab === 'faq' ? 'active' : ''}`}
          onClick={() => setActiveTab('faq')}
        >
          FAQ
        </button>
        {courseResponse?.Topics.length && (
          <button
            className={`tab ${activeTab === 'test' ? 'active' : ''}`}
            onClick={() => setActiveTab('test')}
          >
            Test
          </button>
        )}
        {courseResponse?.Topics.length && (
          <button
            className={`tab ${activeTab === 'enroll' ? 'active' : ''}`}
            onClick={() => setActiveTab('enroll')}
          >
            Enroll
          </button>
        )}
      </div>
      <div className="tab-content">{renderTabContent()}</div>
      <hr></hr>
      <div className="container">
  {courseResponse?.CourseBulletPoints?.length ? (
    <div className="row">
      {courseResponse?.CourseBulletPoints.map((item, index) => (
        <div key={index} className="col-sm-12 col-md-6 mb-3">
          <div className="d-flex align-items-center">
            <div className="icon-container me-3">
              <i className={`fa ${item.icon} fa-2x`}></i>
            </div>
            <div className="text-container">
              <p className="mb-0">{item.title}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p>No bullet points available</p>
  )}
</div>
      <div className='objective'>
        <h1 className='detail-h1'>Our Objective</h1>
        <p>{courseResponse?.objective}</p>
      </div>
      <div className='course-for'>
        <h1 className='detail-h1'>Perfect Fit for Learners Who...</h1>
        {courseResponse?.CourseFor && courseResponse.CourseFor.length > 0 ? (
          <div className='row'>
            {courseResponse.CourseFor.map((item) => (
              <div className="job-posting col-sm-12 col-md-3 col-lg-3 m-1">
                <h2 className='border-bottom text-center'>{item.title}</h2>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <p></p>
        )}
      </div>

      <div className='perks'>
        <h1 className='detail-h1'>Exclusive Perks & Rewards</h1>
        {courseResponse?.CoursePerksBenefits && courseResponse.CoursePerksBenefits.length > 0 ? (
          <div className='row'>
            {courseResponse.CoursePerksBenefits.map((item) => (
              <div className="job-posting col-sm-12 col-md-2 col-lg-2 m-1">

                <div className="perk-card">
                  <div className="perk-card-header">
                    <i className={item.icon}></i>
                    <h2 className="perk-card-title">{item.title}</h2>
                  </div>
                </div>

              </div>
            ))}
          </div>
        ) : (
          <p></p>
        )}
      </div>
      <div className="faq-container">
        <h1 className="detail-h1">Frequently Asked Questions</h1>
        {courseResponse?.Faqs && courseResponse.Faqs.length > 0 ? (
          <div className="row">
            <Accordion defaultActiveKey="0" alwaysOpen>
              {courseResponse.Faqs.map((item, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>{item.description}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ) : (
          <p>No FAQs available</p>
        )}
      </div>
    </div>
  );
}
};

export default CourseDetails;
