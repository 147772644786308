import React from 'react';
import './ProjectRequirement.css';

const ProjectRequirement: React.FC = () => {
  return (
    <div className="project">
      <h1>Project Requirements</h1>
      <p>
        Need assistance with your projects? We provide end-to-end support for various types of projects, including web development, mobile applications, and data analytics.
      </p>
      <h2>Why Choose Us:</h2>
      <ul>
        <li>Expert guidance from industry professionals</li>
        <li>Timely delivery and quality assurance</li>
        <li>Customizable solutions to meet your needs</li>
        <li>Support for academic, professional, and personal projects</li>
      </ul>
      <p>
        Let us know your requirements, and we’ll help you bring your project ideas to life. Contact us today to discuss your needs!
      </p>
    </div>
  );
};

export default ProjectRequirement;
