// src/components/Footer.tsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="text-center py-3 mt-5">
      <div className="footer-bg"></div>
      <div className="container">
        <div className="footer-links">
          <Link className="footer-link" to="/about">
            About
          </Link>
          <Link className="footer-link" to="/contact">
            Contact
          </Link>
          <Link className="footer-link" to="/career">
            Career
          </Link>
        </div>
        <p className="mb-0">© 2024 Mukund Programming Tutorials</p>
        <p className="mb-0">All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
