export interface RoadmapModel{
    courseId: string;
    title: string;
    description: string;
    child:Array<
    {
        title:string;
        description:string;
    }
    >
}
export const roadmapData: RoadmapModel[] = [
    {
      courseId: 'angular-roadmap',
      title: 'Angular Roadmap',
      description: 'A comprehensive guide to mastering Angular development.',
      child: [
        {
          title: 'Introduction to Angular',
          description: 'Understand the basics of Angular, its architecture, and core concepts like components, modules, and services.',
        },
        {
          title: 'Advanced Angular Components',
          description: 'Learn about lifecycle hooks, input/output properties, and custom directives.',
        },
        {
          title: 'Angular Routing',
          description: 'Implement routing to create a single-page application (SPA).',
        },
        {
          title: 'State Management with NgRx',
          description: 'Learn to manage the state of your application using NgRx.',
        },
        {
          title: 'Form Handling',
          description: 'Understand template-driven and reactive forms for building user input forms.',
        },
        {
          title: 'Angular Testing',
          description: 'Test your Angular applications with tools like Jasmine and Karma.',
        },
      ],
    },
    {
      courseId: 'javascript-roadmap',
      title: 'JavaScript Roadmap',
      description: 'Master JavaScript from basic to advanced topics, suitable for front-end and full-stack developers.',
      child: [
        {
          title: 'JavaScript Basics',
          description: 'Learn about variables, data types, functions, and control flow.',
        },
        {
          title: 'DOM Manipulation',
          description: 'Understand how to interact with the Document Object Model (DOM) to create dynamic web pages.',
        },
        {
          title: 'ES6 Features',
          description: 'Explore modern JavaScript features like arrow functions, template literals, and promises.',
        },
        {
          title: 'Asynchronous JavaScript',
          description: 'Work with asynchronous code using callbacks, promises, and async/await.',
        },
        {
          title: 'JavaScript Frameworks',
          description: 'Learn about popular JavaScript frameworks and libraries such as React, Angular, and Vue.js.',
        },
        {
          title: 'JavaScript Testing',
          description: 'Get familiar with testing libraries like Jest and Mocha for writing unit tests.',
        },
      ],
    },
    {
      courseId: 'nodejs-roadmap',
      title: 'Node.js Roadmap',
      description: 'Develop backend applications using Node.js and create scalable network applications.',
      child: [
        {
          title: 'Introduction to Node.js',
          description: 'Learn about Node.js, its architecture, and event-driven programming.',
        },
        {
          title: 'Node.js Modules',
          description: 'Understand built-in modules and how to create custom modules in Node.js.',
        },
        {
          title: 'Express.js Framework',
          description: 'Use Express.js to build web applications and RESTful APIs.',
        },
        {
          title: 'Asynchronous Programming',
          description: 'Dive deeper into handling asynchronous operations using callbacks, promises, and async/await.',
        },
        {
          title: 'Databases with Node.js',
          description: 'Connect and interact with databases like MongoDB and SQL databases.',
        },
        {
          title: 'Authentication and Authorization',
          description: 'Implement authentication and authorization using JWT and OAuth.',
        },
      ],
    },
    {
      courseId: 'reactjs-roadmap',
      title: 'React.js Roadmap',
      description: 'Learn React.js from the basics to building complex applications.',
      child: [
        {
          title: 'Introduction to React',
          description: 'Learn about components, JSX, props, and state in React.',
        },
        {
          title: 'Advanced React Components',
          description: 'Master React hooks, functional components, and class components.',
        },
        {
          title: 'React Router',
          description: 'Implement routing to create a single-page application (SPA) with React Router.',
        },
        {
          title: 'State Management',
          description: 'Learn state management techniques with Context API, Redux, and Zustand.',
        },
        {
          title: 'React Performance Optimization',
          description: 'Improve the performance of your React apps using techniques like memoization and lazy loading.',
        },
        {
          title: 'React Testing',
          description: 'Write tests for your React components using testing libraries like Jest and React Testing Library.',
        },
      ],
    },
    {
        courseId: 'core-java-roadmap',
        title: 'Core Java Roadmap',
        description: 'Master the fundamentals of Java programming and its powerful features.',
        child: [
          { title: 'Java Basics', description: 'Learn about variables, data types, operators, and control flow in Java.' },
          { title: 'Object-Oriented Programming', description: 'Understand classes, objects, inheritance, polymorphism, and encapsulation.' },
          { title: 'Collections Framework', description: 'Master data structures like ArrayList, HashMap, and LinkedList in Java.' },
          { title: 'Exception Handling', description: 'Learn how to handle exceptions and errors gracefully.' },
          { title: 'Java Streams and Lambdas', description: 'Work with streams and lambda expressions for functional programming in Java.' },
          { title: 'Multithreading', description: 'Implement concurrency and parallelism using threads in Java.' },
        ],
      },
      {
        courseId: 'python-roadmap',
        title: 'Python Roadmap',
        description: 'Learn Python programming from scratch to advanced applications.',
        child: [
          { title: 'Python Basics', description: 'Understand variables, data types, and control structures in Python.' },
          { title: 'File Handling', description: 'Learn how to read, write, and manipulate files in Python.' },
          { title: 'Object-Oriented Programming', description: 'Explore classes, objects, inheritance, and polymorphism in Python.' },
          { title: 'Data Analysis with Pandas', description: 'Analyze and manipulate data using the Pandas library.' },
          { title: 'Web Development with Flask/Django', description: 'Create web applications using Flask or Django frameworks.' },
          { title: 'Python Testing', description: 'Write tests for Python code using libraries like PyTest or Unittest.' },
        ],
      },
      {
        courseId: 'go-roadmap',
        title: 'Go Roadmap',
        description: 'Explore Go (Golang) for efficient and scalable programming.',
        child: [
          { title: 'Go Basics', description: 'Understand Go syntax, variables, and data types.' },
          { title: 'Concurrency in Go', description: 'Master Goroutines and Channels for concurrent programming.' },
          { title: 'Go Modules', description: 'Learn how to manage dependencies with Go Modules.' },
          { title: 'Error Handling', description: 'Implement effective error handling in Go applications.' },
          { title: 'Building Web APIs', description: 'Create robust web APIs using Gos standard library.' },
          { title: 'Go Testing', description: 'Write and run tests for Go applications.' },
        ],
      },
      {
        courseId: 'rust-roadmap',
        title: 'Rust Roadmap',
        description: 'Learn Rust programming for systems-level development.',
        child: [
          { title: 'Introduction to Rust', description: 'Learn the basics of Rust and its ownership model.' },
          { title: 'Memory Management', description: 'Master Rusts unique memory safety features.' },
          { title: 'Concurrency in Rust', description: 'Implement concurrency using Rusts threading model.' },
          { title: 'Crate Ecosystem', description: 'Explore popular crates and Rusts package manager, Cargo.' },
          { title: 'Building CLI Applications', description: 'Develop command-line applications with Rust.' },
          { title: 'Rust Testing', description: 'Test Rust applications using built-in testing libraries.' },
        ],
      },
      {
        courseId: 'php-roadmap',
        title: 'PHP Roadmap',
        description: 'Master PHP for web development and server-side scripting.',
        child: [
          { title: 'PHP Basics', description: 'Understand PHP syntax, variables, and control structures.' },
          { title: 'Working with Databases', description: 'Integrate PHP with MySQL and other databases.' },
          { title: 'Object-Oriented PHP', description: 'Learn OOP concepts and apply them in PHP.' },
          { title: 'Building RESTful APIs', description: 'Create APIs for web applications using PHP.' },
          { title: 'PHP Frameworks', description: 'Explore popular frameworks like Laravel and Symfony.' },
          { title: 'PHP Testing', description: 'Write tests for PHP applications using PHPUnit.' },
        ],
      },
      {
        courseId: 'mysql-roadmap',
        title: 'MySQL Roadmap',
        description: 'Learn to design and manage databases using MySQL.',
        child: [
          { title: 'Database Basics', description: 'Understand database concepts and relational design.' },
          { title: 'SQL Queries', description: 'Master SELECT, INSERT, UPDATE, DELETE queries in SQL.' },
          { title: 'Database Optimization', description: 'Optimize queries and database performance.' },
          { title: 'Stored Procedures and Triggers', description: 'Learn to use stored procedures and triggers in MySQL.' },
          { title: 'Database Security', description: 'Implement security best practices for MySQL databases.' },
          { title: 'MySQL Backup and Restore', description: 'Learn to back up and restore databases effectively.' },
        ],
      },
      {
        courseId: 'devops-roadmap',
        title: 'DevOps Roadmap',
        description: 'Master the principles and tools of DevOps.',
        child: [
          { title: 'Introduction to DevOps', description: 'Understand the DevOps lifecycle and its principles.' },
          { title: 'CI/CD Pipeline', description: 'Learn to set up continuous integration and deployment pipelines.' },
          { title: 'Containerization with Docker', description: 'Use Docker for containerizing applications.' },
          { title: 'Infrastructure as Code', description: 'Automate infrastructure with tools like Terraform and Ansible.' },
          { title: 'Monitoring and Logging', description: 'Implement monitoring and logging using tools like Prometheus and ELK Stack.' },
          { title: 'Cloud Services', description: 'Leverage cloud platforms like AWS, Azure, or Google Cloud.' },
        ],
      },
      {
        courseId: 'data-analyst-roadmap',
        title: 'Data Analyst Roadmap',
        description: 'Learn to analyze and interpret data effectively.',
        child: [
          { title: 'Data Basics', description: 'Understand data types, data collection, and preparation.' },
          { title: 'Excel for Analysis', description: 'Use Microsoft Excel for data cleaning and visualization.' },
          { title: 'SQL for Analysis', description: 'Learn SQL queries to manipulate and analyze data.' },
          { title: 'Data Visualization', description: 'Create visualizations using tools like Tableau and Power BI.' },
          { title: 'Statistics Basics', description: 'Apply statistical methods to analyze data trends.' },
          { title: 'Reporting and Insights', description: 'Learn to generate reports and provide actionable insights.' },
        ],
      },
  ];
  