import axios from 'axios';
import { ENDPOINT } from '../utils/Endpoints';

export interface CourseInquiryData {
    name: string;
    mobile: string;
    email: string;
    courseId: string;
    message: string;
}

const API_BASE_URL = `${ENDPOINT}/inquery`; // Replace with your backend API base URL

class CourseInquiryService {
    static async sendCourseInquiry(data: CourseInquiryData): Promise<void> {
        try {
            const response = await axios.post(`${API_BASE_URL}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Course inquiry submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting course inquiry:', error);
            throw new Error('Failed to submit course inquiry');
        }
    }
}

export default CourseInquiryService;
