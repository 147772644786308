import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { useAuth } from '../../context/AuthContext';
import './Signup.css'; // Import custom CSS if needed
import { ENDPOINT } from '../../utils/Endpoints';

const Signup: React.FC = () => {
  const [userData, setUserData] = useState({ name: '', email: '', mobile: '', password: '',insertDate:new Date(),updateDate:new Date() });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [loading, setLoading] = useState(false); // State to handle loading
  const [error, setError] = useState<string | null>(null); // State to handle errors
  const navigate = useNavigate();
  const { login } = useAuth(); // Simulate login after signup

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner
    setError(null); // Reset error state

    try {
      // API call to save user data (replace URL with actual endpoint)
      const response = await axios.post(`${ENDPOINT}/users`, userData);

      // Handle API response
      if (response.status === 201) {
        login(userData.email); // Simulate login after successful signup
        navigate('/dashboard'); // Redirect to dashboard on success
      } else {
        setError('Signup failed. Please try again.');
      }
    } catch (err: any) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false); // Hide loading spinner after request completion
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container signups">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <div className="card shadow">
            <div className="card-body formDiv">
              <h2 className="card-title text-center mb-4">Create account</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={userData.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile"
                    value={userData.mobile}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3 input-group">
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    name="password"
                    placeholder="Password"
                    value={userData.password}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                  <span
                    className="input-group-text bg-white border-0 passIcon"
                    style={{ cursor: 'pointer' }}
                    onClick={togglePasswordVisibility}
                  >
                    <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-primary w-100 mt-3 loginbtn" disabled={loading}>
                  {loading ? 'Signing Up...' : 'Signup'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
