import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { roadmapData, RoadmapModel } from '../../data/roadmapData';
import './RoadmapPage.css'; // Optional CSS for styling
import CourseRoadmap from '../../components/CourseRoadmap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RoadmapPage: React.FC = () => {
  const [selectedRoadmap, setSelectedRoadmap] = useState<RoadmapModel | null>(null);

  // Select a random roadmap on initial load
  useEffect(() => {
    if (roadmapData.length > 0) {
      const randomIndex = Math.floor(Math.random() * roadmapData.length);
      setSelectedRoadmap(roadmapData[randomIndex]);
    }
  }, []);

  const handleChipClick = (courseId: string) => {
    const roadmap = roadmapData.find((roadmap) => roadmap.courseId === courseId);
    setSelectedRoadmap(roadmap || null);
  };

  // Carousel settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4, // Number of chips visible at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="career-page">
      <h1 className="text text-center">Comprehensive Developer Roadmap - Learn and Advance Your Career</h1>
      <p className="text text-center">
        Discover step-by-step guides for learning the skills you need to excel in your software development career. 
        Choose a roadmap, follow a clear path, and achieve your goals.
      </p>

      <Slider {...sliderSettings} className="chips-container">
        {roadmapData.map((roadmap) => (
          <div
            key={roadmap.courseId}
            className="chip"
            onClick={() => handleChipClick(roadmap.courseId)}
            data-title={roadmap.title}
          >
            {roadmap.title}
          </div>
        ))}
      </Slider>

      {selectedRoadmap ? (
        <>
          <h2 className="text text-center">{selectedRoadmap.title}</h2>
          <p className="text text-center">
            {selectedRoadmap.description || 'Explore the curated learning path to master this subject.'}
          </p>
          <CourseRoadmap roadmapData={selectedRoadmap.child} />
        </>
      ) : (
        <p className="text text-center">
          Select a roadmap to explore a tailored learning path and take your development journey to the next level.
        </p>
      )}
    </div>
  );
};

export default RoadmapPage;
