import React, { useState } from 'react';
import './CareerPage.model.css';
import { jobList, JobModel } from '../data/jobData';
import { APPLYNOW } from '../utils/Endpoints';

const jobPostings: JobModel[] = jobList;

const CareerPage: React.FC = () => {
  // Function to redirect to the specified form URL
  const redirectToForm = (url: string) => {
    window.location.href = url;
  };

  // State to manage visibility of job details
  const [visibleJobDetails, setVisibleJobDetails] = useState<number | null>(null);

  const toggleJobDetails = (jobId: number) => {
    setVisibleJobDetails((prev) => (prev === jobId ? null : jobId));
  };

  return (
    <div className="career-page">
      <h1 className="text text-center">Career Opportunities</h1>
      <p className="text text-center">
        Join our growing team and become part of a dynamic environment where your skills will make an impact.
      </p>

      <section className="job-listings">
        {jobPostings.map((job) => (
          <div key={job.id} className="job-posting col-sm-12 col-md-3 col-lg-3">
            <h2>{job.title}</h2>
            <p><strong>Location:</strong> {job.location}</p>
            <p><strong>Description:</strong> {job.description}</p>

            {/* Toggle button */}
            <button onClick={() => toggleJobDetails(job.id)} className='btn btn-warning me-2 viewBtn'>
              {visibleJobDetails === job.id ? 'View Less' : 'View More'}
            </button>

            {/* Show additional details if visibleJobDetails matches the job ID */}
            {visibleJobDetails === job.id && (
              <>
                <p><strong>Qualifications:</strong> {job.qualifications}</p>
                <p><strong>Payment:</strong> {job.payment}</p>
                <p><strong>Schedule:</strong> {job.schedule}</p>
              </>
            )}
                            <button className="btn btn-primary me-2 applyBtn" onClick={() => redirectToForm(job.formURL)}>Apply Now</button>
          </div>
        ))}
      </section>

      <section className="freelance-trainers card-body formDiv">
        <h2>Freelance Training Opportunities</h2>
        <p>We are looking for freelance trainers with expertise in the following areas:</p>
        <ul>
          <li>Java</li>
          <li>Node.js</li>
          <li>PHP</li>
          <li>Angular</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>.NET</li>
          <li>AWS</li>
          <li>Azure</li>
          <li>Android Development</li>
          <li>UI/UX Design</li>
          <li>Go</li>
          <li>Rust</li>
          <li>C++</li>
        </ul>
        <p><strong>Qualifications:</strong> B.Tech (CS), BCA, MCA, M.Tech, M.Sc (CS), B.Sc (IT), pursuing degree or relevant courses can apply.</p>
        <p><strong>Payment:</strong> 100-300 INR per topic, based on experience. Payment can be weekly, monthly, or daily.</p>
        <p><strong>Schedule:</strong> Flexible working hours.</p>
        <button className="apply-button viewBtn" onClick={() => redirectToForm(APPLYNOW)}>Become a Trainer</button>
      </section>
    </div>
  );
};

export default CareerPage;
