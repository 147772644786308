import React from "react";
import "./HeroSection.css";
import { HeroSectionProps } from "../utils/HeroSection";

const HeroSection: React.FC<HeroSectionProps> = ({ imageUrl, link, heading, paragraph, buttonText }) => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>
          {heading.split(" ").map((word, index) => (
            <span key={index} className={word === "Product" || word === "teams" ? "highlight" : ""}>
              {word}{" "}
            </span>
          ))}
        </h1>
        <p>
          {paragraph.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <a href={link} className="hero-button">
          {buttonText}
        </a>
      </div>
      <div className="hero-image d-none d-md-block">
        <img src={imageUrl} alt={buttonText} />
      </div>
    </section>
  );
};

export default HeroSection;
