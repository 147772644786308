import React from 'react';
import './OnlineClass.css';

const OnlineClass: React.FC = () => {
  return (
    <div className="OnlineClass">
      <h1>Online Classes</h1>
      <p>
        Join our online classes and learn from the comfort of your home. 
        Our expert instructors provide live sessions, recorded materials, and interactive Q&A sessions to enhance your learning experience.
      </p>
      <h2>Key Features:</h2>
      <ul>
        <li>Flexible schedules to fit your routine</li>
        <li>Live interactive sessions with instructors</li>
        <li>Access to recorded classes</li>
        <li>Comprehensive course materials</li>
      </ul>
      <p>
        For more information, feel free to contact us. We look forward to helping you achieve your goals!
      </p>
    </div>
  );
};

export default OnlineClass;
