// src/data/coursesData.ts

export interface Course {
  id: number;
  title: string;
  description: string;
  image: string;
  fee: number;
  rating: number;
  numberOfTopics: number;
  badgeText: string; // Badge text (e.g., "Premium")
  source: string; // Add this property to define if the course is local or external
  externalLink?: string; // Optional external link
  categoryDisplayId?: string;
  courseDisplayId?: string;
  Topics: Array<Topics>;
  resources?: Array<Resource>;
  prerequisites?: Array<Prerequisit>;
  Faqs?: Array<Faq>;
  tests?: Array<Test>;
  objective?: string;
  CourseFor?: Array<CourseFor>;
  authoor?: Array<CourseAuthor>;
  projects?:Array<CourseProject>;
  certificate?:Array<CourseCertificate>;
  CoursePerksBenefits?:Array<CoursePerksBenifites>
  learnings?:CourseLearnings;
  coupons?:Array<CourseCoupons>;
  discount?:number;
  CourseBulletPoints?:Array<CourseBulletPoints>
}
export interface CourseBulletPoints
{
  id?:number;
  title:string;
  icon?:string;
  courseId:number;
}
export interface CourseCoupons
{
id?:number;
code:string;
price:number;
isExpired:boolean;
courseId:number;

}
export interface CourseLearnings
{
id?:number;
title:string;
topicList?:Array<CourseLearningsTopics>
courseId:number;

}
export interface CourseLearningsTopics
{
  id?:number;
  title:string;
  icon?:string;
  learningId:string;
}
export interface CoursePerksBenifites
{
id?:number;
title:string;
description:string;
icon?:string;
courseId:number;

}
export interface CourseCertificate
{
id?:number;
title:string;
description:string;
image?:string;
courseId:number;

}
export interface CourseProject
{
id?:number;
title:string;
description:string;
image?:string;
link?:string;
features?:Array<ProjectFeatures>;
courseId:number;

}
export interface ProjectFeatures
{
id?:number;
title:string;
courseId:number;

}
export interface CourseAuthor
{
id?:number;
title:string;
description:string;
image?:string;
linkedin?:string;
courseId:number;

}

export interface CourseFor
{
id?:number;
title:string;
description:string;
courseId:number;

}
export interface Test {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface Faq {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface Prerequisit {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface Resource {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  courseId:number;

}
export interface Topics {
  id: number;
  title: string;
  description: string;
  externalLink?: string;
  source: string;
  isFree: boolean;
  courseId: number;
}

const courses: Course[] = [];

export default courses;
