import React from 'react';
import { Course } from '../data/coursesData';
import { addToCart } from '../services/CartService'; // Import the addToCart function
import './CourseCard.css'; // Optional styling
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';

interface CourseCardProps {
  course: Course;
  onAddToCart: (course: Course) => void; // Pass the course to the handler
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onAddToCart }) => {
  const { updateCartItems } = useCart();
  const handleAddToCart = () => {
    addToCart(course); // Add the course to the cart
    updateCartItems(); // Update the cart count in Navbar
  };
  const {
    id,
    title,
    description,
    image,
    fee,
    rating,
    Topics,
    badgeText,
    source,
    externalLink,
    courseDisplayId,
  } = course;

  return (
    <div className="c-card">

      <div className="card mpt-course-card">
        <div className="card-image-container">
          <img src={image} alt={title} className="img-fluid" />
          <span className="badge premium-badge">{badgeText}</span>
        </div>
        <div className="card-body">
          {source !== 'local' && externalLink !== '' ? (
            <h5 className="card-title truncate1" title={title}>{title}</h5>

          ) : (

            <Link
              to={`/course-details/${courseDisplayId}/${id}`} className='card-title truncate1'
            >
              <h5 title={title}>{title}</h5>
            </Link>
          )}
          <div className="course-details">
            {fee < 1 ? (
              <div className="course-badge free-course">Free</div>
            ) : (
              <div className="course-badge paid-course">
                👑{fee} INR</div>

            )}

            <div className="rating">
              <span>{rating} ⭐</span>
            </div>
            <div className='course-badge course-topics'>Topics: {Topics.length}</div>

          </div>
          <p className="card-text truncate" title={description}>{description}</p>

          <div className="button-container">
            {source !== 'local' && externalLink !== '' ? (
              // External link
              <a
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="button enroll-button mt-2"
              >
                View
              </a>
            ) : (
              // Internal link
              <Link
                to={`/course-details/${courseDisplayId}/${id}`}
                className="button enroll-button mt-2"
              >
                Enroll now
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
