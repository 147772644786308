import React, { useState } from 'react';
import './CourseRoadmap.css'; // Custom CSS file for styling
import { FaGripHorizontal, FaGripVertical } from 'react-icons/fa'; // Icons from react-icons

// Type definition for each step in the course roadmap
interface RoadmapStep {
  title: string;
  description: string;
}

// Props for the CourseRoadmap component
interface CourseRoadmapProps {
  roadmapData: RoadmapStep[];
}

// A reusable component for displaying a custom course roadmap
const CourseRoadmap: React.FC<CourseRoadmapProps> = ({ roadmapData }) => {
  const [layout, setLayout] = useState<'horizontal' | 'vertical'>('vertical');

  return (
    <div className="custom-roadmap-container">
      <div className="layout-selector">
        <button
          className={`layout-icon ${layout === 'horizontal' ? 'active' : ''} hide-on-mobile`}
          onClick={() => setLayout('horizontal')}
          title="Horizontal Layout"
        >
          <FaGripHorizontal />
        </button>
        <button
          className={`layout-icon ${layout === 'vertical' ? 'active' : ''}`}
          onClick={() => setLayout('vertical')}
          title="Vertical Layout"
        >
          <FaGripVertical />
        </button>
      </div>
      <div className={`roadmap ${layout}`}>
        {roadmapData.map((step, index) => (
          <div key={index} className={`roadmap-step ${layout}`}>
            <div className="step-content">
              <div className="step-number">{index + 1}</div>
              <h3 className="step-title">{step.title}</h3>
              <p className="step-description">{step.description}</p>
            </div>
            {index < roadmapData.length - 1 && <div className={`step-connector ${layout}`} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseRoadmap;
