import React from 'react';
import './OfflineClass.css';
const OfflineClass: React.FC = () => {
  return (
    <div className="OfflineClass">
      <h1>Offline Classes</h1>
      <p>
        Our offline classes are conducted in state-of-the-art facilities with hands-on learning opportunities. 
        Interact directly with our instructors and other learners in a collaborative environment.
      </p>
      <h2>Key Highlights:</h2>
      <ul>
        <li>Personalized attention in small groups</li>
        <li>Hands-on practice and practical examples</li>
        <li>Classroom activities to reinforce learning</li>
        <li>Located in multiple cities for easy accessibility</li>
      </ul>
      <p>
        Enroll now to enjoy an immersive learning experience. Contact us for details on schedules and locations.
      </p>
    </div>
  );
};

export default OfflineClass;
